<template>
  <div>
    <div class="flex mb-4 items-center justify-between">
      <h1 class="font-medium text-3xl mr-4">{{ train.name }}</h1>

      <div class="flex flex-1 mr-2">
        <p
          v-if="train.mileage_to_next_instruction"
          class="py-1 px-3 rounded"
          :class="
            train.color == 1
              ? 'bg-green-300'
              : train.color == 2
              ? 'bg-yellow-400 '
              : train.color == 3
              ? 'bg-red-300 '
              : 'bg-purple-100'
          "
        >
          {{ $t("mileage_to_next_instruction") }}
          {{ train.mileage_to_next_instruction }}
        </p>
        <p
          v-if="train.corrective_types_in_process"
          class="ml-2 py-1 px-3 rounded"
          :class="
            train.color == 1
              ? 'bg-green-300'
              : train.color == 2
              ? 'bg-yellow-400 '
              : train.color == 3
              ? 'bg-red-300 '
              : 'bg-purple-100'
          "
        >
          {{ $t("current_corrective_type") }}: <el-tag size="small"
          :type="train.color == 1
              ? 'success'
              : train.color == 2
              ? 'warning'
              : train.color == 3
              ? 'danger'
              : ''"
              class="mr-1" effect="dark" v-for="name in train.corrective_types_in_process" :key="name">{{ name }}</el-tag>
        </p>
      </div>
      <el-button
        type="success"
        size="small"
        v-if="role == 100"
        @click="open = true"
      >
        <i class="el-icon-plus"></i> {{ $t("add_carriage") }}
      </el-button>
    </div>
    <div
      class="bg-white rounded shadow-md p-3 border w-full"
      v-loading="loading_page"
    >
      <div class="flex flex-wrap -m-1 lg:-m-1.5 xl:-m-1 3xl:-m-2">
        <div class="w-1/5 p-1 lg:p-1.5 xl:p-1 3xl:p-2">
          <div
            class="p-1 px-2 xl:p-1 xl:px-2 h-full border border-gray-300 rounded bg-blue-400 bg-opacity-40 shadow-md"
          >
            <p class="text-sm lg:text-base mb-0.5">{{ $t("mileage") }}</p>

            <p class="text-lg lg:text-xl xl:text-2xl font-medium">
              {{ train.mileage }} (km)
            </p>
          </div>
        </div>
        <div class="w-1/5 p-1 lg:p-1.5 xl:p-1 3xl:p-2">
          <div
            class="p-1 px-2 xl:p-1 xl:px-2 h-full border border-gray-300 rounded bg-green-500 bg-opacity-40 shadow-md"
          >
            <p class="text-sm lg:text-base mb-0.5">
              {{ $t("total_repairs_count") }}
            </p>

            <p class="text-lg lg:text-xl xl:text-2xl font-medium">
              {{ train.total_repairs_count }}
            </p>
          </div>
        </div>
        <div class="w-1/5 p-1 lg:p-1.5 xl:p-1 3xl:p-2">
          <div
            class="p-1 px-2 xl:p-1 xl:px-2 h-full border border-gray-300 bg-amber-400 bg-opacity-70 rounded shadow-md"
          >
            <p class="text-sm lg:text-base mb-0.5">
              {{ $t("not_completed_repairs_count") }}
            </p>

            <p class="text-lg lg:text-xl xl:text-2xl font-medium">
              {{ train.not_completed_repairs_count }}
            </p>
          </div>
        </div>
        <div class="w-1/5 p-1 lg:p-1.5 xl:p-1 3xl:p-2">
          <div
            class="p-1 px-2 xl:p-1 xl:px-2 h-full border border-gray-300 bg-orange-400 bg-opacity-70 rounded shadow-md"
          >
            <p class="text-sm lg:text-base mb-0.5">
              {{ $t("need_spare_repairs_count") }}
            </p>

            <p class="text-lg lg:text-xl xl:text-2xl font-medium">
              {{ train.need_spare_repairs_count }}
            </p>
          </div>
        </div>
        <div class="w-1/5 p-1 lg:p-1.5 xl:p-1 3xl:p-2">
          <div
            class="p-1 px-2 xl:p-1 xl:px-2 h-full border border-gray-300 bg-rose-400 bg-opacity-90 rounded shadow-md"
          >
            <p class="text-sm lg:text-base mb-0.5">
              {{ $t("postponed_repairs_count") }}
            </p>

            <p class="text-lg lg:text-xl xl:text-2xl font-medium">
              {{ train.postponed_repairs_count }}
            </p>
          </div>
        </div>
      </div>
      <div
        class="overflow-auto whitespace-nowrap w-full mt-4"
        v-loading="loading_carraige_add"
      >
        <button
          class="inline-block align-text-top"
          v-for="carriage in carriages"
          :key="carriage.id"
          @click="filterCarriage(carriage.id)"
        >
          <div
            class="h-40 flex items-center"
            :class="
              carriage.id === filteredCarriage.id
                ? 'bg-indigo-400 bg-opacity-40'
                : carriage.not_completed_repairs_count
                ? 'bg-amber-400  bg-opacity-70'
                : carriage.need_spare_repairs_count
                ? 'bg-orange-400 bg-opacity-70'
                : carriage.postponed_repairs_count
                ? 'bg-rose-400 bg-opacity-90'
                : ''
            "
          >
            <img :src="carriage.type.photo" alt="" />
          </div>
          <p class="text-center p-3">{{ carriage.type.name }}</p>
        </button>
      </div>
      <div v-show="filteredCarriage" class="my-4">
        <div class="flex items-center mb-3">
          <p class="text-xl font-medium mb-3 w-full">
            {{ $t("carriage_information") }}
          </p>
        </div>
        <el-table
          :data="filteredCarriageTable"
          border
          v-loading="loading_carraige_add"
          style="width: 100%"
          class="rounded shadow mb-4"
        >
          <el-table-column align="center" prop="id" label="ID" width="60">
          </el-table-column>
          <el-table-column prop="name" :label="$t('name')" min-width="300">
          </el-table-column>
          <el-table-column
            prop="second_name"
            :label="$t('second_name')"
            min-width="300"
          >
          </el-table-column>
          <el-table-column prop="number" :label="$t('number')" min-width="90">
          </el-table-column>
          <el-table-column prop="code" :label="$t('code')" min-width="90">
          </el-table-column>

          <el-table-column :label="$t('repair_count')" align="center">
            <el-table-column
              prop="total_repairs_count"
              :label="$t('total_repairs_count')"
              width="75"
            >
            </el-table-column>
            <el-table-column
              prop="not_completed_repairs_count"
              :label="$t('not_completed_repairs_count')"
              min-width="140"
            >
            </el-table-column>
            <el-table-column
              prop="need_spare_repairs_count"
              :label="$t('need_spare_repairs_count')"
              min-width="150"
            >
            </el-table-column>
            <el-table-column
              prop="postponed_repairs_count"
              :label="$t('postponed_repairs_count')"
              min-width="130"
            >
            </el-table-column>
          </el-table-column>
          <el-table-column
            :label="$t('actions')"
            fixed="right"
            width="120"
            v-if="role == 100"
          >
            <template slot-scope="scope">
              <el-button size="mini" @click="openCarriageEdit(scope.row.id)">
                <i class="el-icon-edit"></i>
              </el-button>
              <el-popconfirm
                :confirm-button-text="$t('yes')"
                :cancel-button-text="$t('no_thanks')"
                icon="el-icon-info"
                icon-color="red"
                :title="$t('you_want_to_delete')"
                class="ml-2"
                @confirm="CarriageDelete(scope.row.id)"
              >
                <el-button
                  slot="reference"
                  icon="el-icon-delete"
                  size="mini"
                  type="danger"
                >
                </el-button>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
        <div class="flex justify-between py-2">
          <h3 class="text-xl font-medium">{{ $t("equipments") }}</h3>
          <el-button
            @click="openDialog(filteredCarriage.id)"
            type="success"
            size="small"
            v-if="role == 100"
          >
            <i class="el-icon-plus"></i> {{ $t("add") }}
          </el-button>
        </div>
        <el-table
          :data="filteredCarriage.equipments"
          border
          v-loading="loading_equipment_add"
          style="width: 100%"
          class="rounded shadow mb-4"
        >
          <el-table-column align="center" prop="id" label="ID" width="60">
          </el-table-column>
          <el-table-column prop="type.name" :label="$t('name')" min-width="300">
          </el-table-column>
          <el-table-column
            prop="serial_number"
            :label="$t('serial_number')"
            min-width="300"
          >
          </el-table-column>
          <el-table-column
            :label="$t('actions')"
            fixed="right"
            width="180"
            v-if="role == 100"
          >
            <template slot-scope="scope">
              <el-button
                icon="el-icon-tickets"
                size="mini"
                @click="openLog(scope.row.id)"
              >
              </el-button>
              <el-button
                size="mini"
                @click="openEquipmentEdit(scope.row.id, filteredCarriage.id)"
              >
                <i class="el-icon-edit"></i>
              </el-button>
              <el-popconfirm
                :confirm-button-text="$t('yes')"
                :cancel-button-text="$t('no_thanks')"
                icon="el-icon-info"
                icon-color="red"
                :title="$t('you_want_to_delete')"
                class="ml-2"
                @confirm="EquipmentDelete(scope.row.id, filteredCarriage.id)"
              >
                <el-button
                  slot="reference"
                  icon="el-icon-delete"
                  size="mini"
                  type="danger"
                >
                </el-button>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
        <div class="flex items-center mb-3 mt-6">
          <p class="text-xl font-medium w-full">
            {{ $t("repairs") }}
          </p>
        </div>
        <el-table
          :data="repairList"
          border
          v-loading="loading_repairs"
          style="width: 100%"
          class="rounded-lg border shadow-md mt-3"
          >
            <el-table-column align="center" prop="id" label="ID" width="60">
            </el-table-column>
            <el-table-column
                  align="center"
                  prop="crash_code"
                  :label="$t('crash_code')"
                  width="130"
                >
            </el-table-column>
            <el-table-column  min-width="320" :label="$t('comment')" prop="comment" align="center">
            </el-table-column>
            <el-table-column align="center" prop="author.full_name" :label="$t('author')" width="180">
            </el-table-column>
            <el-table-column  align="center" :label="$t('status')" width="120">
                  <template slot-scope="scope">
                    <el-tag
                      size="mini"
                      type="info"
                      v-if="scope.row.status == 0"
                      >{{ $t("REPAIRING") }}</el-tag
                    >
                    <el-tag
                      size="mini"
                      type="success"
                      v-if="scope.row.status == 1"
                      >{{ $t("DONE") }}</el-tag
                    >
                    <el-tag
                      size="mini"
                      type="warning"
                      v-if="scope.row.status == 2"
                      >{{ $t("NEED_SPARE") }}</el-tag
                    >
                    <el-tag size="mini" v-if="scope.row.status == 3">{{
                      $t("POSTPONED")
                    }}</el-tag>
                  </template>
            </el-table-column>
            <el-table-column
                  fixed="right"
                  :label="$t('actions')"
                  align="center"
                  width="100"
                >
                  <template slot-scope="scope">
                    <el-button
                      size="large"
                      @click="$router.push(`/inspections/${scope.row.inspection.id}`)"
                    >
                      <i class="el-icon-view"></i>
                    </el-button>
                  </template>
            </el-table-column>
        </el-table>
        <el-dialog
          :visible.sync="dialogEquipment"
          @closed="closeDialog"
          width="400px"
        >
          <span slot="title" class="text-xl font-medium">{{
            $t("add_equipment")
          }}</span>
          <div>
            <el-form :model="equipment" ref="equipment" label-position="top">
              <el-form-item
                :label="$t('serial_number')"
                prop="serial_number"
                :rules="{
                  required: true,
                  message: $t('please_input'),
                  trigger: 'blur',
                }"
                style="margin-bottom: 8px"
              >
                <el-input
                  :placeholder="$t('input')"
                  v-model="equipment.serial_number"
                ></el-input>
              </el-form-item>
              <el-form-item
                :label="$t('type')"
                prop="type"
                :rules="{
                  required: true,
                  message: $t('please_select'),
                  trigger: 'blur',
                }"
              >
                <el-select
                  v-model="equipment.type"
                  :placeholder="$t('select')"
                  class="w-full"
                >
                  <el-option
                    v-for="equipment_type in equipment_types"
                    :key="equipment_type.id"
                    :label="equipment_type.name"
                    :value="equipment_type.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-form>
            <div class="flex justify-end">
              <el-button v-if="edit_equipment" @click="EditEquipment">{{
                $t("save")
              }}</el-button>
              <el-button v-else @click="SaveEquipment">{{
                $t("save")
              }}</el-button>
            </div>
          </div>
        </el-dialog>
      </div>
      <div v-show="!filteredCarriage" class="mt-4">
        <h2 class="text-xl font-medium">{{ $t("inspections") }}</h2>
        <el-table
          :data="inspections"
          border
          style="width: 100%"
          class="rounded-lg border shadow-md mt-3"
        >
          <el-table-column align="center" prop="id" label="ID" width="60">
          </el-table-column>
          <el-table-column
            :label="$t('author')"
            prop="author.full_name"
            min-width="200"
          >
          </el-table-column>
          <el-table-column :label="$t('number')" prop="number">
          </el-table-column>
          <el-table-column width="300" :label="$t('sample')" prop="sample.name">
          </el-table-column>
          <el-table-column
            :label="$t('responsible_person')"
            prop="responsible_person.full_name"
            min-width="200"
          >
          </el-table-column>
          <el-table-column :label="$t('workers')" min-width="200">
            <template slot-scope="scope">
              <div v-for="worker in scope.row.workers" :key="worker.id">
                {{ worker.full_name }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('comment')"
            prop="comment"
            min-width="200"
          >
          </el-table-column>
          <el-table-column
            prop="created_time"
            :label="$t('created_time')"
            width="180"
          >
          </el-table-column>
          <el-table-column
            prop="last_updated_time"
            :label="$t('last_updated_time')"
            min-width="250"
          >
          </el-table-column>
          <el-table-column :label="$t('actions')" fixed="right" min-width="90">
            <template slot-scope="scope">
              <router-link :to="`/inspections/${scope.row.id}`" class="mr-2">
                <el-button size="medium" icon="el-icon-view"> </el-button>
              </router-link>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <el-dialog :title="$t('logs')" class="log_dialog" :visible.sync="logDialog">
      <el-table :data="equipment_logs" border v-loading="log_loading">
        <el-table-column
          property="user"
          :label="$t('user')"
          min-width="150"
        ></el-table-column>
        <el-table-column
          property="text"
          :label="$t('text')"
          min-width="300"
        ></el-table-column>
        <el-table-column
          property="created_time"
          :label="$t('created_time')"
          width="180"
        ></el-table-column>
      </el-table>
    </el-dialog>

    <el-drawer
      :visible.sync="open"
      :with-header="false"
      size="400px"
      @close="CloseDrawer"
    >
      <div
        class="h-20 bg-111E52 flex items-center justify-between px-3 text-white text-xl"
      >
        <p>EXSERVICE</p>
        <button @click="open = false" class="px-2">
          <i class="el-icon-close"></i>
        </button>
      </div>
      <div class="p-4">
        <h1 class="mb-4">{{ $t("add_carriage") }}</h1>
        <el-form
          ref="carriage"
          :rules="rules"
          label-position="top"
          :model="carriage"
        >
          <el-form-item
            prop="name"
            :rules="{
              required: true,
              message: $t('please_input'),
              trigger: 'blur',
            }"
            style="margin-bottom: 10px"
            :label="$t('name')"
          >
            <el-input
              :placeholder="$t('input')"
              v-model="carriage.name"
              clearable
            >
            </el-input>
          </el-form-item>
          <el-form-item
            prop="second_name"
            :rules="{
              required: true,
              message: $t('please_input'),
              trigger: 'blur',
            }"
            style="margin-bottom: 10px"
            :label="$t('second_name')"
          >
            <el-input
              :placeholder="$t('input')"
              v-model="carriage.second_name"
              clearable
            >
            </el-input>
          </el-form-item>
          <el-form-item
            prop="type"
            :rules="{
              required: true,
              message: $t('please_select'),
              trigger: 'blur',
            }"
            style="margin-bottom: 10px"
            :label="$t('type')"
          >
            <el-select
              v-model="carriage.type"
              :placeholder="$t('select')"
              class="w-full"
            >
              <el-option
                v-for="carriage_type in carriage_types"
                :key="carriage_type.id"
                :label="carriage_type.name"
                :value="carriage_type.id"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <div class="flex -mx-1">
            <el-form-item
              prop="outer_number"
              style="margin-bottom: 10px"
              :label="$t('outer_number')"
              class="w-1/2 inline-block px-1"
            >
              <el-input
                :placeholder="$t('input')"
                v-model.number="carriage.outer_number"
                clearable
              >
                >
              </el-input>
            </el-form-item>
            <el-form-item
              prop="order"
              style="margin-bottom: 10px"
              :label="$t('order')"
              class="w-1/2 inline-block px-1"
            >
              <el-input
                :placeholder="$t('input')"
                v-model.number="carriage.order"
                clearable
              >
              </el-input>
            </el-form-item>
          </div>
          <div class="flex -mx-1">
            <el-form-item
              prop="number"
              style="margin-bottom: 10px"
              :label="$t('number')"
              class="w-1/2 inline-block px-1"
            >
              <el-input
                :placeholder="$t('input')"
                v-model.number="carriage.number"
                clearable
              >
              </el-input>
            </el-form-item>
            <el-form-item
              prop="code"
              :rules="{
                required: true,
                message: $t('please_input'),
                trigger: 'blur',
              }"
              style="margin-bottom: 10px"
              :label="$t('code')"
              class="w-1/2 inline-block px-1"
            >
              <el-input
                :placeholder="$t('input')"
                v-model="carriage.code"
                clearable
              >
              </el-input>
            </el-form-item>
          </div>
        </el-form>
        <div class="text-right">
          <el-button
            v-if="!edit_carriage"
            @click="addCarriage"
            type="primary"
            :loading="loading_carraige_add"
          >
            {{ $t("add") }}
          </el-button>
          <el-button
            v-else
            @click="editCarriage"
            type="primary"
            :loading="loading_carraige_add"
          >
            {{ $t("save") }}
          </el-button>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "TrainsDetail",
  data() {
    var checkNumber = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t("please_input")));
      }
      if (!Number.isInteger(value)) {
        callback(new Error(this.$t("please_input_digits")));
      } else {
        callback();
      }
    };
    return {
      logDialog: false,
      log_loading: false,
      equipment_logs: [],
      inspections: [],
      train: "",
      carriages: [],
      filteredCarriage: "",
      loading_page: false,
      open: false,
      carriage: {
        train: "",
        order: "",
        name: "",
        second_name: "",
        outer_number: "",
        number: "",
        code: "",
        type: "",
      },
      carriage_types: "",
      edit_carriage: false,
      carriageId: "",
      loading_repairs: false,
      repairList: [],
      loading_carraige_add: false,
      rules: {
        order: [{ validator: checkNumber, trigger: "blur" }],
        outer_number: [{ validator: checkNumber, trigger: "blur" }],
        number: [{ validator: checkNumber, trigger: "blur" }],
      },

      dialogEquipment: false,
      loading_equipment_add: false,
      edit_equipment: false,
      equipment_types: "",
      equipmentId: "",
      equipment: {
        carriage: "",
        serial_number: "",
        type: "",
      },
      role: localStorage.role,
    };
  },
  computed: {
    filteredCarriageTable() {
      let data = [];
      data.push(this.filteredCarriage);
      return data;
    },
  },
  mounted() {
    this.getInspections();
    this.getTrain();
    this.getCarriages();
    this.getCarriageTypes();
    this.getEquipmentTypes();
  },
  methods: {
    openLog(id) {
      this.logDialog = true;
      this.log_loading = true;
      axios
        .get(`equipments/${id}/logs/`)
        .then((response) => {
          this.log_loading = false;
          this.equipment_logs = response.data.map((data) => {
            return {
              ...data,
              created_time: data.created_time
                .split(".")
                .shift()
                .replace("T", " "),
            };
          });
        })
        .catch(() => {
          this.log_loading = false;
        });
    },
    getTrain() {
      this.loading_page = true;
      axios
        .get(`trains/${this.$route.params.id}/`)
        .then((response) => {
          this.train = response.data;
          this.loading_page = false;
        })
        .catch(() => {
          this.loading_page = false;
        });
    },
    getCarriages() {
      this.loading_carraige_add = true;
      return new Promise((resolve, reject) => {
        axios
          .get(`carriages/?no_page&train=${this.$route.params.id}`)
          .then((response) => {
            this.loading_carraige_add = false;
            this.carriages = response.data.sort((a, b) =>
              a.order > b.order ? 1 : -1
            );
            resolve();
          })
          .catch(() => {
            this.loading_carraige_add = false;
            reject();
          });
      });
    },
    getInspections() {
      this.loading_page = true;
      axios
        .get(`inspections/?train=${this.$route.params.id}` + (this.role == 9 ? `&is_signed=false` : ``))
        .then((response) => {
          this.inspections = response.data.results.map((data) => {
            return {
              ...data,
              created_time: data.created_time
                .split(".")
                .shift()
                .replace("T", " "),
              last_updated_time: data.last_updated_time
                .split(".")
                .shift()
                .replace("T", " "),
            };
          });

          this.loading_page = false;
        })
        .catch(() => (this.loading_page = false));
    },
    filterCarriage(id) {
      if (!this.filteredCarriage) {
        this.filteredCarriage = this.carriages.find((data) => data.id === id);
        this.getRepairList(id);
      } else if (this.filteredCarriage.id === id) {
        this.filteredCarriage = "";
      } else {
        this.getRepairList(id);
        this.filteredCarriage = this.carriages.find((data) => data.id === id);
      }
    },
    getRepairList(id) {
      this.loading_repairs = true;
      axios.get(`repairs/?no_page&carriage=${id}`).then((response) => {
        this.loading_repairs = false;
        this.repairList = response.data;
      });
    },
    getCarriageTypes() {
      axios.get("carriage_types/?no_page").then((response) => {
        this.carriage_types = response.data;
      });
    },
    getEquipmentTypes() {
      axios.get("equipment_types/?no_page").then((response) => {
        this.equipment_types = response.data;
      });
    },
    addCarriage() {
      this.carriage.train = this.$route.params.id;
      this.$refs.carriage.validate((valid) => {
        if (valid) {
          this.loading_carraige_add = true;
          axios
            .post("carriages/", this.carriage)
            .then(() => {
              this.loading_carraige_add = false;
              this.getCarriages();
              this.open = false;
              this.$notify({
                title: this.$t("success"),
                message: this.$t("data_added"),
                type: "success",
              });
            })
            .catch(() => {
              this.loading_carraige_add = false;
              this.$notify.error({
                title: this.$t("error"),
                message: this.$t("no_data_added"),
              });
            });
        }
      });
    },
    CloseDrawer() {
      this.edit_carriage = false;
      Object.keys(this.carriage).forEach((key) => {
        this.carriage[key] = "";
      });
      this.$refs.carriage.clearValidate();
    },
    async CarriageDelete(id) {
      this.loading_carraige_add = true;
      await axios
        .delete(`carriages/${id}/`)
        .then(() => {
          this.loading_carraige_add = false;
          this.getCarriages();
          this.filteredCarriage = "";
          this.$notify({
            title: this.$t("success"),
            message: this.$t("data_deleted"),
            type: "success",
          });
        })
        .catch(() => {
          this.loading_carraige_add = false;
          this.$notify.error({
            title: this.$t("error"),
            message: this.$t("data_not_deleted"),
          });
        });
    },
    openCarriageEdit(id) {
      this.open = true;
      this.edit_carriage = true;
      this.carriageId = id;
      Object.keys(this.carriage).forEach((key) => {
        if (typeof this.filteredCarriage[key] === "object") {
          this.carriage[key] = this.filteredCarriage[key].id;
        } else {
          this.carriage[key] = this.filteredCarriage[key];
        }
      });
    },
    editCarriage() {
      this.$refs.carriage.validate((valid) => {
        if (valid) {
          this.loading_carraige_add = true;
          axios
            .patch(`carriages/${this.carriageId}/`, this.carriage)
            .then(() => {
              this.loading_carraige_add = false;
              this.open = false;
              this.getCarriages().then(() => {
                this.filteredCarriage = this.carriages.find(
                  (data) => data.id === this.carriageId
                );
              });
              this.$notify({
                title: this.$t("success"),
                message: this.$t("data_changed"),
                type: "success",
              });
            })
            .catch(() => {
              this.open = false;
              this.loading_carraige_add = false;
              this.$notify.error({
                title: this.$t("error"),
                message: this.$t("data_unchanged"),
              });
            });
        }
      });
    },

    openDialog(id) {
      this.equipment.carriage = id;
      this.carriageId = id;
      this.dialogEquipment = true;
    },
    SaveEquipment() {
      this.$refs.equipment.validate((valid) => {
        if (valid) {
          this.loading_equipment_add = true;
          axios
            .post("equipments/", this.equipment)
            .then(() => {
              this.loading_equipment_add = false;
              this.dialogEquipment = false;
              this.getCarriages().then(() => {
                this.filteredCarriage = this.carriages.find(
                  (data) => data.id === this.equipment.carriage
                );
              });
              this.$notify({
                title: this.$t("success"),
                message: this.$t("data_added"),
                type: "success",
              });
            })
            .catch(() => {
              this.loading_equipment_add = false;
              this.$notify.error({
                title: this.$t("error"),
                message: this.$t("no_data_added"),
              });
            });
        }
      });
    },
    closeDialog() {
      this.edit_equipment = false;
      Object.keys(this.equipment).forEach((key) => {
        this.equipment[key] = "";
      });
      this.$refs.equipment.clearValidate();
    },
    async EquipmentDelete(equipmentId, carriageId) {
      this.loading_equipment_add = true;
      await axios
        .delete(`equipments/${equipmentId}/`)
        .then(() => {
          this.getCarriages().then(() => {
            this.filteredCarriage = this.carriages.find(
              (data) => data.id === carriageId
            );
          });
          this.loading_equipment_add = false;
          this.$notify({
            title: this.$t("success"),
            message: this.$t("data_deleted"),
            type: "success",
          });
        })
        .catch(() => {
          this.loading_equipment_add = false;
          this.$notify.error({
            title: this.$t("error"),
            message: this.$t("data_not_deleted"),
          });
        });
    },
    openEquipmentEdit(equipmentId, carriageId) {
      this.dialogEquipment = true;
      this.edit_equipment = true;
      this.equipmentId = equipmentId;
      this.edit_equipment = this.carriages
        .find((data) => data.id === carriageId)
        .equipments.find((data) => data.id === equipmentId);
      Object.keys(this.equipment).forEach((key) => {
        if (typeof this.edit_equipment[key] === "object") {
          this.equipment[key] = this.edit_equipment[key].id;
        } else {
          this.equipment[key] = this.edit_equipment[key];
        }
      });
    },
    EditEquipment() {
      this.$refs.equipment.validate((valid) => {
        if (valid) {
          this.loading_equipment_add = true;
          axios
            .patch(`equipments/${this.equipmentId}/`, this.equipment)
            .then(() => {
              this.loading_equipment_add = false;
              this.dialogEquipment = false;
              this.getCarriages().then(() => {
                this.filteredCarriage = this.carriages.find(
                  (data) => data.id === this.equipment.carriage
                );
              });
              this.$notify({
                title: this.$t("success"),
                message: this.$t("data_changed"),
                type: "success",
              });
            })
            .catch(() => {
              this.loading_equipment_add = false;
              this.$notify.error({
                title: this.$t("error"),
                message: this.$t("data_unchanged"),
              });
            });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
